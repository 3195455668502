import { Layout } from 'components/access-code/common';
import { SEO } from 'components/common';
import React from 'react';

export const Head = () => <SEO title="404: Not found" />;
const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
