/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useSiteMetadata } from 'hooks/use-site-metadata';
import PropTypes from 'prop-types';
import React from 'react';

interface Props {
  description: string;
  title: string;
}

function SEO({ description, title: defaultTitle }: Props) {
  const siteMetadata = useSiteMetadata();

  const metaDescription = description || siteMetadata?.description || '';
  const title = `${defaultTitle} | ${siteMetadata?.title || ''}`;

  return (
    <>
      {/* General tags */}
      {defaultTitle && <title>{title}</title>}
      <meta name="description" content={metaDescription} />
      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteMetadata?.author || ''} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {/* NOTE: OSANO SCRIPT MUST BE THE FIRST LOADED SCRIPT IN ORDER TO PROPERLY BLOCK LOADING OF COOKIES */}
      <link
        rel="stylesheet"
        href="https://cdn.everlywell.com/osano/banner.css"
      />
      <script
        data-cfasync="false"
        type="text/javascript"
        src="https://cmp.osano.com/16A6XpTloLmXW5QGm/576d3be3-6e35-400e-a9f2-cc8cc5e17d8e/osano.js?language=en&variant=three"
      ></script>
    </>
  );
}

SEO.defaultProps = {
  description: ``,
  title: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SEO;
