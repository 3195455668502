/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import everlywellLogo from 'assets/images/everlywellLogo.svg';
import { useThemeContext } from 'contexts/theme-context';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
// local imports
import GlobalStyle from 'styles/global';
import { REGISTER_KIT_LINK } from 'utils/constants';
import { SupportLink } from 'components/ui';

import * as S from './styles';

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const { selectedTheme } = useThemeContext();
  return (
    <>
      <ThemeProvider theme={selectedTheme}>
        <span data-portal-type="access_code" hidden />
        <S.SiteHeader
          loggedIn={false}
          logoSrc={everlywellLogo}
          logoAlt="everlywell logo"
          topLevelLinks={{
            register: {
              label: 'Register Kit',
              url: REGISTER_KIT_LINK,
            },
          }}
          onMenuClose={() => { }}
          cartComponent={<SupportLink />}
        />
        <S.Layout>
          <main>{children}</main>
        </S.Layout>
      </ThemeProvider>
      <GlobalStyle theme={selectedTheme} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
