import React from 'react';

import * as S from './styles';

interface Props {
  pageName: string;
  customData?: object;
}

const AnalyticsPageMeta = (props: Props) => {
  return (
    <S.Hidden
      className="hidden-page-name-element"
      data-page-name={props.pageName}
      data-track-custom-data={JSON.stringify(props.customData || {})}
    ></S.Hidden>
  );
};

export default AnalyticsPageMeta;
