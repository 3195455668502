import React, { useContext } from 'react';

// local imports
import { ariesTheme } from 'utils/constants/styles';

export const ThemeContext = React.createContext<{ selectedTheme: typeof ariesTheme; }>({
  selectedTheme: ariesTheme
});

export const ThemeContextProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeContext.Provider value={{ selectedTheme: ariesTheme }}>
      {children}
    </ ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  return context ?? { selectedTheme: ariesTheme };
};
