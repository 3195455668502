import { AnalyticsPageMeta } from 'components/common';
import { navigate } from 'gatsby';
import React from 'react';
import { isInnerNavigate, initInnerNavigate } from 'utils/services/auth';

interface Props {
  component: React.ElementType;
  location: typeof window.location;
  analyticsPageName: string;
}

const PrivateRoute = ({ component: Component, location, analyticsPageName, ...rest }: Props) => {
  if (!isInnerNavigate() && location.pathname !== ``) {
    navigate('/');

    return null;
  }

  initInnerNavigate();

  return (
    <>
      <AnalyticsPageMeta pageName={analyticsPageName} />
      <Component {...rest} />
    </>
  );
};

export default PrivateRoute;
