import { SEO } from 'components/common';
import React from 'react';

interface Props {
  location: typeof window.location;
}

const TEST_TAKER_PAGE_TITLES: Record<string, string> = {
  '/app/payment-steps/personal-info': 'Personal Information',
  '/app/payment-steps/available-tests': 'Available Tests',
  '/app/payment-steps/payment': 'Shipping & Payment Information',
  '/app/payment-steps/review-order': 'Review Your Order',
  '/app/payment-steps/success': 'Order Placed',
};

const PrivateHead = ({ location }: Props) => {
  let title = '';

  if (location.pathname.includes('/payment-steps')) {
    title = TEST_TAKER_PAGE_TITLES[location.pathname] ?? '';
  }

  if (location.pathname.includes('/app/order-placed')) {
    title = 'Order Placed';
  }

  if (location.pathname.includes('/app/wizard')) {
    title = 'Personal Information';
  }

  return <SEO title={title} />;
};

export default PrivateHead;
