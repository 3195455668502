import { graphql, useStaticQuery } from 'gatsby';

interface SiteData {
  site: {
    siteMetadata: {
      author: string | null;
      description: string | null;
      title: string | null;
    }
  }
}

export const useSiteMetadata = () => {
  const {
    site: { siteMetadata },
  }: SiteData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            title
          }
        }
      }
    `,
  );

  return siteMetadata;
};
